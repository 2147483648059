import React from "react";
import { useParams } from "react-router-dom";
import { Ship, Truck, Plane, Package, FileText, Clock } from 'lucide-react';

const services = [
  {
    icon: <Ship className="h-8 w-8 text-blue-600 dark:text-white" />,
    title: "Transport Maritime",
    description: "Solutions de transport maritime international pour les cargaisons en vrac",
    slug: "transport-maritime",
    fullDescription: "Notre service de transport maritime offre des solutions complètes et fiables pour l'expédition de vos marchandises à travers les océans. Nous gérons tous types de cargaisons, des conteneurs aux vraquiers, en assurant une livraison sûre et ponctuelle. Notre réseau mondial de partenaires et notre expertise en logistique maritime garantissent une gestion optimale de votre fret maritime.",
    features: [
      "Transport conteneurisé et vrac",
      "Suivi en temps réel des expéditions",
      "Documentation maritime complète",
      "Assurance maritime disponible",
      "Solutions personnalisées selon vos besoins"
    ]
  },
  {
    icon: <Plane className="h-8 w-8 text-blue-600 dark:text-white" />,
    title: "Fret Aérien",
    description: "Services de fret aérien express pour les livraisons urgentes",
    slug: "fret-aerien",
    fullDescription: "Notre service de fret aérien est conçu pour répondre à vos besoins de transport rapide et urgent. Avec des connexions dans les principaux aéroports mondiaux, nous garantissons des délais de livraison optimaux pour vos marchandises sensibles au temps.",
    features: [
      "Service express et standard",
      "Couverture mondiale",
      "Manutention spécialisée",
      "Solutions charter disponibles",
      "Tracking en temps réel"
    ]
  },
  {
    icon: <Truck className="h-8 w-8 text-blue-600 dark:text-white" />,
    title: "Transport Terrestre",
    description: "Réseau complet de transport terrestre",
    slug: "transport-terrestre",
    fullDescription: "Notre réseau de transport terrestre couvre l'ensemble du territoire avec des solutions adaptées à tous types de marchandises. Que ce soit pour des livraisons locales ou internationales, notre flotte moderne et nos chauffeurs expérimentés assurent un service fiable et professionnel.",
    features: [
      "Transport national et international",
      "Livraison dernier kilomètre",
      "Véhicules adaptés à tout type de fret",
      "Géolocalisation des véhicules",
      "Service express disponible"
    ]
  },
  {
    icon: <Package className="h-8 w-8 text-blue-600 dark:text-white" />,
    title: "Entreposage",
    description: "Solutions sécurisées de stockage et de gestion des stocks",
    slug: "entreposage",
    fullDescription: "Nos solutions d'entreposage offrent des espaces de stockage sécurisés et modernes, équipés des dernières technologies de gestion des stocks. Nous proposons des services complets de logistique d'entrepôt pour optimiser votre chaîne d'approvisionnement.",
    features: [
      "Stockage sécurisé 24/7",
      "Gestion des stocks informatisée",
      "Préparation de commandes",
      "Cross-docking",
      "Inventaire en temps réel"
    ]
  },
  {
    icon: <FileText className="h-8 w-8 text-blue-600 dark:text-white" />,
    title: "Dédouanement",
    description: "Gestion experte des documents et procédures douanières",
    slug: "dedouanement",
    fullDescription: "Notre équipe d'experts en douane facilite vos opérations d'import-export en prenant en charge l'ensemble des formalités douanières. Nous assurons la conformité de vos expéditions avec les réglementations en vigueur.",
    features: [
      "Déclarations douanières",
      "Conseil en réglementation",
      "Obtention des licences",
      "Classification tarifaire",
      "Gestion documentaire"
    ]
  },
  {
    icon: <Clock className="h-8 w-8 text-blue-600 dark:text-white" />,
    title: "Support 24/7",
    description: "Service client et suivi de cargaison 24h/24 et 7j/7",
    slug: "support-24-7",
    fullDescription: "Notre service client est disponible 24h/24 et 7j/7 pour répondre à toutes vos questions et gérer vos demandes urgentes. Une équipe multilingue dédiée assure un suivi permanent de vos expéditions.",
    features: [
      "Assistance téléphonique 24/7",
      "Support multilingue",
      "Gestion des urgences",
      "Suivi en temps réel",
      "Reporting personnalisé"
    ]
  }
];

const ServiceDetail = () => {
  const { slug } = useParams();
  const service = services.find((s) => s.slug === slug);

  if (!service) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-800">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Service non trouvé</h1>
          <a href="/services" className="mt-4 inline-block text-blue-600 dark:text-blue-400 hover:underline">
            Retour aux services
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen py-20 bg-gray-50 dark:bg-gray-800">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white dark:bg-gray-700 rounded-lg shadow-lg p-8">
          <div className="flex items-center justify-center mb-6">
            <div className="w-16 h-16 bg-blue-100 dark:bg-blue-900 rounded-full flex items-center justify-center">
              {service.icon}
            </div>
          </div>
          
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white text-center mb-4">
            {service.title}
          </h1>
          
          <p className="text-xl text-gray-600 dark:text-gray-300 text-center mb-8">
            {service.description}
          </p>

          <div className="prose prose-lg dark:prose-invert max-w-full mb-12">
            <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
              {service.fullDescription}
            </p>
          </div>

          <div className="bg-gray-50 dark:bg-gray-800 rounded-lg p-6 mb-8">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              Caractéristiques principales
            </h2>
            <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {service.features.map((feature, index) => (
                <li key={index} className="flex items-center text-gray-600 dark:text-gray-300">
                  <svg className="h-5 w-5 text-blue-600 dark:text-blue-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  {feature}
                </li>
              ))}
            </ul>
          </div>

          <div className="flex justify-center">
            <a
              href="/services"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
            >
              Retour aux services
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetail;
