export default function Footer() {
  return <footer className="bg-gray-900 dark:bg-black text-white py-12">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        <div>
          <h3 className="text-lg font-semibold mb-4">À Propos</h3>
          <p className="text-gray-400">
            Leader en logistique mondiale avec plus de deux décennies d'expérience dans le commerce international.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">Liens Rapides</h3>
          <ul className="space-y-2 text-gray-400">
            <li><a href="/services" className="hover:text-white">Services</a></li>
            <li><a href="/about" className="hover:text-white">À Propos</a></li>
            <li><a href="/blog" className="hover:text-white">Blog</a></li>
            <li><a href="/contact" className="hover:text-white">Contact</a></li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">Services</h3>
          <ul className="space-y-2 text-gray-400">
            <li><a href="/services#maritime" className="hover:text-white">Fret Maritime</a></li>
            <li><a href="/services#aerien" className="hover:text-white">Fret Aérien</a></li>
            <li><a href="/services#terrestre" className="hover:text-white">Transport Terrestre</a></li>
            <li><a href="/services#entreposage" className="hover:text-white">Entreposage</a></li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-4">Contact</h3>
          <ul className="space-y-2 text-gray-400">
            <li>Bololo</li>
            <li>Dakar, Sénégal</li>
            <li>+235 66303314</li>
            <li>contact@kingdor.com</li>
          </ul>
        </div>
      </div>
      <div className="mt-12 pt-8 border-t border-gray-800 text-center text-gray-400">
        <p>© 2024 Kingdor. Tous droits réservés.</p>
      </div>
    </div>
  </footer>
}