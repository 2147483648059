export default function ImportExport(){
  return (
    <div className="pt-32 pb-20 bg-white dark:bg-gray-900 transition-colors duration-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white sm:text-5xl">
            Services d'Import-Export
          </h1>
          <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
            Solutions complètes pour vos besoins en commerce international
          </p>
        </div>

        {/* Services Grid */}
        <div className="grid md:grid-cols-2 gap-12 mb-16">
          <div className="bg-gray-50 dark:bg-gray-800 p-8 rounded-lg">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Services d'Importation</h2>
            <ul className="space-y-4 text-gray-600 dark:text-gray-300">
              <li className="flex items-center">
                <span className="mr-2">✓</span>
                Gestion des documents d'importation
              </li>
              <li className="flex items-center">
                <span className="mr-2">✓</span>
                Dédouanement des marchandises
              </li>
              <li className="flex items-center">
                <span className="mr-2">✓</span>
                Conformité réglementaire
              </li>
              <li className="flex items-center">
                <span className="mr-2">✓</span>
                Coordination avec les fournisseurs
              </li>
            </ul>
          </div>

          <div className="bg-gray-50 dark:bg-gray-800 p-8 rounded-lg">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Services d'Exportation</h2>
            <ul className="space-y-4 text-gray-600 dark:text-gray-300">
              <li className="flex items-center">
                <span className="mr-2">✓</span>
                Documentation d'exportation
              </li>
              <li className="flex items-center">
                <span className="mr-2">✓</span>
                Certification des produits
              </li>
              <li className="flex items-center">
                <span className="mr-2">✓</span>
                Gestion des licences d'exportation
              </li>
              <li className="flex items-center">
                <span className="mr-2">✓</span>
                Support logistique international
              </li>
            </ul>
          </div>
        </div>

        {/* Process Section */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white text-center mb-12">
            Notre Processus
          </h2>
          <div className="grid md:grid-cols-4 gap-8">
            <div className="text-center">
              <div className="bg-blue-100 dark:bg-blue-900 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-2xl">1</span>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Consultation</h3>
              <p className="text-gray-600 dark:text-gray-300">
                Évaluation de vos besoins commerciaux
              </p>
            </div>
            <div className="text-center">
              <div className="bg-blue-100 dark:bg-blue-900 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-2xl">2</span>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Planification</h3>
              <p className="text-gray-600 dark:text-gray-300">
                Développement de la stratégie
              </p>
            </div>
            <div className="text-center">
              <div className="bg-blue-100 dark:bg-blue-900 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-2xl">3</span>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Exécution</h3>
              <p className="text-gray-600 dark:text-gray-300">
                Mise en œuvre des opérations
              </p>
            </div>
            <div className="text-center">
              <div className="bg-blue-100 dark:bg-blue-900 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4">
                <span className="text-2xl">4</span>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-2">Suivi</h3>
              <p className="text-gray-600 dark:text-gray-300">
                Monitoring et support continu
              </p>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
            Prêt à développer votre commerce international?
          </h2>
          <button className="bg-blue-600 text-white px-8 py-3 rounded-md hover:bg-blue-700 transition">
            Demander un devis
          </button>
        </div>
      </div>
    </div>
  );
}