import React from 'react';
import { Clock10,MapPin, MailIcon, Menu, PhoneCall, X } from 'lucide-react';
import logo192 from '../assets/logo192.png';
import { Link } from 'react-router-dom';

export default function Navbar() {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <div className="bg-white border-b-4 border-blue-600 dark:bg-white py-2 sticky top-0 text-gray-800 z-50 hidden lg:block">
  <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-4">
    <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center space-y-2 sm:space-y-0">
   
      <div className="flex items-center space-x-4">
        <span className="flex items-center space-x-2">
          <div className="bg-blue-600 p-2 rounded-full">
            <PhoneCall className="h-6 w-6 text-white" />
          </div>
          <span className="text-sm">Téléphone</span>
        </span>
      </div>
      <div className="flex items-center space-x-4">
        <span className="flex items-center space-x-2">
          <div className="bg-blue-600 p-2 rounded-full">
            <MailIcon className="h-6 w-6 text-white" />
          </div>
          <span className="text-sm">Email</span>
        </span>
      </div>
      <div className="flex items-center space-x-4">
        <span className="flex items-center space-x-2">
          <div className="bg-blue-600 p-2 rounded-full">
            <MapPin className="h-6 w-6 text-white" />
          </div>
          <span className="text-sm">Location</span>
        </span>
      </div>
     
      <div className="flex items-center space-x-4">
        <span className="flex items-center space-x-2">
          <div className="bg-blue-600 p-2 rounded-full">
            <Clock10 className="h-6 w-6 text-white" />
          </div>
          <span className="text-sm">9:00 AM - 5:00 PM</span>
        </span>
      </div>
    </div>
  </div>
</div>


      <nav className="bg-white dark:bg-gray-800 shadow-lg fixed w-full z-50 transition-colors duration-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-20 items-center">
            <div className="flex items-center">
              <Link to="/" className="flex items-center">
                <img src={logo192} alt="Kingdor Logo" className="h-14 w-14" />
                <span className="ml-2 text-xl font-bold text-gray-800 dark:text-white">kingdor</span>
              </Link>
            </div>

            <div className="hidden lg:flex items-center space-x-8">
              <Link to="/" className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors">Accueil</Link>
              <Link to="/services" className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors">Services</Link>
              <Link to="/about" className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors">A propos</Link>
              <Link to="/Import" className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors">Import & Export</Link>
              <Link to="/Logistique" className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors">Logistique</Link>
              <Link to="/blog" className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400 transition-colors">Blog</Link>
              <Link to="/contact" className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 transition-colors">
                Contactez
              </Link>
            </div>

            <div className="lg:hidden flex items-center">
              <button 
                onClick={() => setIsOpen(!isOpen)}
                className="p-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
                aria-label={isOpen ? "Close menu" : "Open menu"}
              >
                {isOpen ? <X className="h-6 w-6 dark:text-white" /> : <Menu className="h-6 w-6 dark:text-white" />}
              </button>
            </div>
          </div>
        </div>

        {isOpen && (
          <div className="lg:hidden absolute top-full left-0 right-0 bg-white dark:bg-gray-800 shadow-lg transition-all duration-300 ease-in-out">
            <div className="px-4 py-3 space-y-2">
              <Link to="/" className="block px-3 py-2 rounded-md text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-blue-600 dark:hover:text-blue-400 transition-colors">Accueil</Link>
              <Link to="/services" className="block px-3 py-2 rounded-md text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-blue-600 dark:hover:text-blue-400 transition-colors">Services</Link>
              <Link to="/about" className="block px-3 py-2 rounded-md text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-blue-600 dark:hover:text-blue-400 transition-colors">A propos</Link>
              <Link to="/Import" className="block px-3 py-2 rounded-md text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-blue-600 dark:hover:text-blue-400 transition-colors">Import & Export</Link>
              <Link to="/Logistique" className="block px-3 py-2 rounded-md text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-blue-600 dark:hover:text-blue-400 transition-colors">Logistique</Link>
              <Link to="/blog" className="block px-3 py-2 rounded-md text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-blue-600 dark:hover:text-blue-400 transition-colors">Blog</Link>
              <Link to="/contact" className="block px-3 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 transition-colors text-center">
                Contactez
              </Link>
            </div>
          </div>
        )}
      </nav>
    </>
  );
}
