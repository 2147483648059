import React, { useState } from "react";
import { Mail, Phone, MapPin, Loader2 } from "lucide-react";
import axios from "axios";

const INITIAL_FORM_STATE = {
  name: "",
  email: "",
  message: "",
};

export default function Contact() {
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [touched, setTouched] = useState({
    name: false,
    email: false,
    message: false,
  });

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const getValidationError = (field) => {
    if (!touched[field]) return "";
    
    switch (field) {
      case "name":
        return formData.name.length < 2 ? "Le nom doit contenir au moins 2 caractères" : "";
      case "email":
        return !formData.email 
          ? "L'email est requis" 
          : !validateEmail(formData.email) 
          ? "Veuillez entrer une adresse email valide" 
          : "";
      case "message":
        return formData.message.length < 10 ? "Le message doit contenir au moins 10 caractères" : "";
      default:
        return "";
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleBlur = (e) => {
    const { id } = e.target;
    setTouched(prev => ({
      ...prev,
      [id]: true,
    }));
  };

  const isFormValid = () => {
    return (
      formData.name.length >= 2 &&
      validateEmail(formData.email) &&
      formData.message.length >= 10
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      setTouched({
        name: true,
        email: true,
        message: true,
      });
      return;
    }

    setLoading(true);
    setSuccess(false);
    setError("");

    try {
      const response = await axios.post("http://localhost:5000/send-email", formData);
      if (response.data.success) {
        setSuccess(true);
        setFormData(INITIAL_FORM_STATE);
        setTouched({
          name: false,
          email: false,
          message: false,
        });
      } else {
        setError(response.data.message || "Une erreur s'est produite lors de l'envoi de l'email. Veuillez réessayer.");
      }
    } catch (error) {
      console.error("Erreur :", error);
      setError(
        error.response?.data?.message || 
        "Une erreur s'est produite lors de l'envoi de l'email. Veuillez réessayer."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative bg-gradient-to-r from-blue-900 to-gray-900 pt-20">
      <div className="absolute inset-0 z-0">
        <img
          className="w-full h-full object-cover opacity-40 mix-blend-overlay"
          src="https://images.unsplash.com/photo-1577563908411-5077b6dc7624?auto=format&fit=crop&q=80"
          alt="Contact background"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/50"></div>
      </div>

      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-white mb-4">Contactez-nous</h1>
          <p className="text-gray-300 max-w-2xl mx-auto">
            Nous sommes là pour répondre à vos questions et vous accompagner dans vos projets
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Contact Info Cards */}
          <div className="space-y-8">
            <div className="bg-white/10 backdrop-blur-lg p-6 rounded-lg hover:bg-white/15 transition-colors">
              <Phone className="h-6 w-6 text-blue-400 mb-4" />
              <h3 className="text-xl font-semibold text-white mb-2">Téléphone</h3>
              <p className="text-gray-300">+235 66303314</p>
            </div>

            <div className="bg-white/10 backdrop-blur-lg p-6 rounded-lg hover:bg-white/15 transition-colors">
              <Mail className="h-6 w-6 text-blue-400 mb-4" />
              <h3 className="text-xl font-semibold text-white mb-2">Email</h3>
              <p className="text-gray-300">contact@kingdor.td</p>
            </div>

            <div className="bg-white/10 backdrop-blur-lg p-6 rounded-lg hover:bg-white/15 transition-colors">
              <MapPin className="h-6 w-6 text-blue-400 mb-4" />
              <h3 className="text-xl font-semibold text-white mb-2">Adresse</h3>
              <p className="text-gray-300">Tchad, N'Djamena, Bololo</p>
            </div>
          </div>

          {/* Contact Form */}
          <form className="bg-white/10 backdrop-blur-lg p-8 rounded-lg" onSubmit={handleSubmit}>
            {error && (
              <div className="mb-4 p-3 bg-red-500/20 border border-red-500 rounded text-red-100">
                {error}
              </div>
            )}
            
            {success && (
              <div className="mb-4 p-3 bg-green-500/20 border border-green-500 rounded text-green-100">
                Message envoyé avec succès!
              </div>
            )}

            <div className="mb-6">
              <label htmlFor="name" className="block text-white mb-2">Nom</label>
              <input
                type="text"
                id="name"
                className={`w-full bg-white/10 border ${
                  getValidationError("name") 
                    ? "border-red-500" 
                    : "border-gray-600"
                } rounded-md px-4 py-2 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors`}
                placeholder="Votre nom"
                value={formData.name}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              {getValidationError("name") && (
                <p className="mt-1 text-sm text-red-400">
                  {getValidationError("name")}
                </p>
              )}
            </div>

            <div className="mb-6">
              <label htmlFor="email" className="block text-white mb-2">Email</label>
              <input
                type="email"
                id="email"
                className={`w-full bg-white/10 border ${
                  getValidationError("email") 
                    ? "border-red-500" 
                    : "border-gray-600"
                } rounded-md px-4 py-2 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors`}
                placeholder="Votre email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              {getValidationError("email") && (
                <p className="mt-1 text-sm text-red-400">
                  {getValidationError("email")}
                </p>
              )}
            </div>

            <div className="mb-6">
              <label htmlFor="message" className="block text-white mb-2">Message</label>
              <textarea
                id="message"
                rows="4"
                className={`w-full bg-white/10 border ${
                  getValidationError("message") 
                    ? "border-red-500" 
                    : "border-gray-600"
                } rounded-md px-4 py-2 text-white focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors`}
                placeholder="Votre message"
                value={formData.message}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              {getValidationError("message") && (
                <p className="mt-1 text-sm text-red-400">
                  {getValidationError("message")}
                </p>
              )}
            </div>

            <button
              type="submit"
              className={`w-full flex items-center justify-center gap-2 py-3 rounded-md transition-all ${
                loading || !isFormValid()
                  ? "bg-blue-600/50 cursor-not-allowed"
                  : "bg-blue-600 hover:bg-blue-700"
              } text-white`}
              disabled={loading || !isFormValid()}
            >
              {loading ? (
                <>
                  <Loader2 className="w-5 h-5 animate-spin" />
                  Envoi en cours...
                </>
              ) : (
                "Envoyer"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
