import React from 'react';

const posts = [
  {
    title: 'L\'Avenir des Chaînes d\'Approvisionnement Mondiales',
    excerpt: 'Découvrez comment la technologie et le développement durable transforment la logistique internationale.',
    image: 'https://images.unsplash.com/photo-1494412574643-ff11b0a5c1c3?auto=format&fit=crop&q=80',
    date: '16 Mars 2024',
    readTime: '5 min de lecture',
    category: 'Technologie',
    author: 'Marie Diop',
    authorRole: 'Expert en Logistique'
  },
  {
    title: 'Guide des Réglementations Import/Export',
    excerpt: 'Un guide complet sur la conformité et la documentation du commerce international.',
    image: 'https://images.unsplash.com/photo-1521587760476-6c12a4b040da?auto=format&fit=crop&q=80',
    date: '14 Mars 2024',
    readTime: '8 min de lecture',
    category: 'Réglementations',
    author: 'Amadou Sow',
    authorRole: 'Consultant en Commerce International'
  },
  {
    title: 'Pratiques Logistiques Durables',
    excerpt: 'Comment les entreprises réduisent leur empreinte carbone dans le transport mondial.',
    image: 'https://images.unsplash.com/photo-1532601224476-15c79f2f7a51?auto=format&fit=crop&q=80',
    date: '12 Mars 2024',
    readTime: '6 min de lecture',
    category: 'Développement Durable',
    author: 'Fatou Fall',
    authorRole: 'Spécialiste en Développement Durable'
  }
];

export default function Blog() {
  return (
    <section id="blog" className="py-24 bg-white dark:bg-gray-900 transition-colors duration-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white sm:text-4xl lg:text-5xl">
            Dernières Actualités
          </h2>
          <p className="mt-6 text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            Restez informé des dernières tendances et analyses d'experts en logistique mondiale et gestion de la chaîne d'approvisionnement
          </p>
        </div>

        <div className="mt-20 grid gap-10 md:grid-cols-2 lg:grid-cols-3">
          {posts.map((post, index) => (
            <article 
              key={index} 
              className="group bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2"
            >
              <div className="relative h-56 overflow-hidden">
                <img
                  className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                  src={post.image}
                  alt={post.title}
                  loading="lazy"
                />
                <div className="absolute top-4 right-4">
                  <span className="px-4 py-2 text-sm font-semibold bg-blue-600 text-white rounded-full shadow-md">
                    {post.category}
                  </span>
                </div>
              </div>
              <div className="p-8">
                <div className="flex items-center space-x-4 mb-4">
                  <div className="flex-shrink-0">
                    <div className="w-10 h-10 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center">
                      <span className="text-blue-600 dark:text-blue-300 font-semibold">
                        {post.author.charAt(0)}
                      </span>
                    </div>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-900 dark:text-white">{post.author}</p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">{post.authorRole}</p>
                  </div>
                </div>
                <div className="flex items-center text-sm text-gray-500 dark:text-gray-400 mb-4">
                  <time dateTime={post.date}>{post.date}</time>
                  <span className="mx-2">•</span>
                  <span>{post.readTime}</span>
                </div>
                <h3 className="text-xl font-bold text-gray-900 dark:text-white group-hover:text-blue-600 dark:group-hover:text-blue-400 transition-colors duration-200">
                  {post.title}
                </h3>
                <p className="mt-4 text-base text-gray-600 dark:text-gray-300 line-clamp-3">
                  {post.excerpt}
                </p>
                <div className="mt-6">
                  <a 
                    href="#" 
                    className="inline-flex items-center text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 font-semibold group-hover:translate-x-2 transition-all duration-300"
                    aria-label={`Lire plus sur ${post.title}`}
                  >
                    Lire la suite 
                    <svg className="ml-2 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </a>
                </div>
              </div>
            </article>
          ))}
        </div>

        <div className="mt-16 text-center">
          <button 
            className="inline-flex items-center px-8 py-4 border-2 border-blue-600 dark:border-blue-400 text-blue-600 dark:text-blue-400 rounded-lg hover:bg-blue-600 hover:text-white dark:hover:bg-blue-400 dark:hover:text-gray-900 transition-all duration-300 font-bold text-lg shadow-lg hover:shadow-xl"
            aria-label="Voir tous les articles du blog"
          >
            Voir tous les articles
            <svg className="ml-3 w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
}