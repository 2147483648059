import React from 'react';
import { Ship, Truck, Plane, Package, FileText, Clock } from 'lucide-react';

export default function Services() {
  const services = [
    {
      icon: <Ship className="h-8 w-8 text-blue-600 dark:text-white" />,
      title: 'Transport Maritime',
      description: 'Solutions de transport maritime international pour les cargaisons en vrac',
      slug: 'transport-maritime',
      bgImage: 'https://images.unsplash.com/photo-1494412574643-ff11b0a5c1c3?auto=format&fit=crop&q=80'
    },
    {
      icon: <Plane className="h-8 w-8 text-blue-600 dark:text-white" />,
      title: 'Fret Aérien', 
      description: 'Services de fret aérien express pour les livraisons urgentes',
      slug: 'fret-aerien',
      bgImage: 'https://images.unsplash.com/photo-1436491865332-7a61a109cc05?auto=format&fit=crop&q=80'
    },
    {
      icon: <Truck className="h-8 w-8 text-blue-600 dark:text-white" />,
      title: 'Transport Terrestre',
      description: 'Réseau complet de transport terrestre',
      slug: 'transport-terrestre',
      bgImage: 'https://images.unsplash.com/photo-1519003722824-194d4455a60c?auto=format&fit=crop&q=80'
    },
    {
      icon: <Package className="h-8 w-8 text-blue-600 dark:text-white" />,
      title: 'Entreposage',
      description: 'Solutions sécurisées de stockage et de gestion des stocks',
      slug: 'entreposage',
      bgImage: 'https://images.unsplash.com/photo-1553413077-190dd305871c?auto=format&fit=crop&q=80'
    },
    {
      icon: <FileText className="h-8 w-8 text-blue-600 dark:text-white" />,
      title: 'Dédouanement',
      description: 'Gestion experte des documents et procédures douanières',
      slug: 'dedouanement',
      bgImage: 'https://images.unsplash.com/photo-1450101499163-c8848c66ca85?auto=format&fit=crop&q=80'
    },
    {
      icon: <Clock className="h-8 w-8 text-blue-600 dark:text-white" />,
      title: 'Support 24/7',
      description: 'Service client et suivi de cargaison 24h/24 et 7j/7',
      slug: 'support-24-7',
      bgImage: 'https://images.unsplash.com/photo-1534536281715-e28d76689b4d?auto=format&fit=crop&q=80'
    }
  ];

  return (
    <section id="services" className="py-20 bg-gray-50 dark:bg-gray-800 transition-colors duration-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white sm:text-4xl">Nos Services</h2>
          <p className="mt-4 text-xl text-gray-600 dark:text-gray-300">
            Des solutions logistiques complètes adaptées à vos besoins
          </p>
        </div>

        <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {services.map((service, index) => (
            <div
              key={index}
              className="relative group bg-blue-100 dark:bg-gray-700 p-6 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden"
              style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(${service.bgImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div className="flex items-center justify-center w-16 h-16 bg-blue-100 dark:bg-blue-900 rounded-full group-hover:bg-white dark:group-hover:bg-white transition-colors duration-300">
                {service.icon}
              </div>
              <h3 className="mt-8 text-xl font-semibold text-gray-900 dark:text-white">{service.title}</h3>
              <p className="mt-4 text-gray-600 dark:text-gray-300">{service.description}</p>
              <a href={`/services/${service.slug}`} className="mt-4 inline-flex items-center text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300">
                En savoir plus
                <svg className="ml-2 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}